<!-- Usage with a `#copy` slot:

<MarkdownViewer
  :source="'```js\nconsole.log('hello, world!')\n```'"
  copy-btn
>
  <template #copy="{ cls, copy }">
    <button
      :class="cls"
      :style="{ top: 'auto', bottom: '8px', color: 'red', background: 'lightblue' }"
      v-text="'Customized copy btn!'"
      @click="copy"
    />
  </template>
</MarkdownViewer>
-->

<template>
  <TuiViewer
    ref="vueTuiViewer"
    class="MarkdownViewer"
    :dir="$store.getters['ext/RTL_SWITCHER_ENABLED'] ? 'auto' : null"
    v-bind="attrs"
    @beforePreviewRender="listenImgLoad"
    v-on="$listeners"
  />
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import 'highlight.js/styles/github.css'
import '../scss/tui-editor.sass'
import 'prismjs/themes/prism.css'
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'

import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import Prism from 'prismjs'

import copyToClipboard from 'copy-to-clipboard'
import Vue from 'vue'

import imageCaptionMixin from '@/mixins/mdImageCaption'

const TuiViewer = () => import(
  /* webpackChunkName: "tui-editor" */
  /* webpackPrefetch: true */
  '@toast-ui/vue-editor'
).then(module => module.Viewer)

const copyHandleClass = 'MarkdownViewer__copy-handle'
const copyHandleSelector = '.' + copyHandleClass

export default {
  name: 'MarkdownViewer',

  components: {
    TuiViewer,
  },

  mixins: [
    imageCaptionMixin,
  ],

  inheritAttrs: false,

  props: {
    source: { type: String, default: '' },
    createVueInstance: {
      type: Function,
      // to assign `vuetify`, `router`, `store`, etc. for slots
      default: (...args) => new Vue(...args),
    },
    copyBtn: { type: Boolean, default: false },
    gallery: { type: String, default: null },
  },

  computed: {
    attrs() {
      const attrs = {
        ...this.$attrs,
        initialValue: this.source,
        options: {
          ...(this.$attrs.options || {}),
          plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        },
      }
      if (this.gallery) attrs['data-gallery'] = this.gallery
      return attrs
    },
  },

  watch: {
    async source(source) {
      await TuiViewer()
      await this.$nextTick()
      // list of available methods: https://nhn.github.io/tui.editor/latest/ToastUIEditorViewer
      // `invoke` is defined here: https://github.com/nhn/tui.editor/blob/d9e82b25e85893c47114692369b1f15cc23061cf/apps/vue-editor/src/mixin/option.js#L34
      this.$refs.vueTuiViewer.invoke('setMarkdown', source)
      this.listenImgLoad()
    },

    copyBtn: {
      async handler() {
        await TuiViewer()
        await this.$nextTick()
        await new Promise(resolve => requestAnimationFrame(resolve))
        if (this.copyBtn) this.tweakCodeBlocksAddCopyBtn()
        else this.removeCopyBtns()
      },
      immediate: true,
    },
  },

  methods: {
    async tweakCodeBlocksAddCopyBtn() {
      if (process.env.NODE_ENV === 'test') {
        await this._depsResolved()
        await new Promise(resolve => setTimeout(resolve, 100))
      }

      const prepared =
        Array.from(this.$el.querySelectorAll('pre > code'))
          .map(code => {
            const pre = code.parentNode
            const oldHandle = pre.querySelector(copyHandleSelector)
            // JEST: jsdom doesn't support `innerText`, that's why `textContent`
            // https://github.com/jsdom/jsdom/issues/1245
            const copyBtn = this.createCopyHandle(pre.textContent)
            return {
              vNode: copyBtn,
              apply: (copyBtn) => {
                if (oldHandle) oldHandle.remove()
                pre.append(copyBtn)
              },
            }
          })
      const btns = prepared.map(prep => prep.vNode)
      const mounted = Array.from(this.mountHandles(btns))
      mounted.forEach((node, i) => prepared[i].apply(node))
    },

    async removeCopyBtns() {
      if (process.env.NODE_ENV === 'test') {
        await this._depsResolved()
        await new Promise(resolve => setTimeout(resolve, 100))
      }

      Array.from(this.$el.querySelectorAll(copyHandleSelector))
        .forEach(btn => btn.remove())
    },

    createCopyHandle(codeText) {
      const copySlot = this.$scopedSlots.copy
      const copy = () => { copyToClipboard(codeText) }
      const style = { position: 'absolute' }

      return copySlot
        // can also be customized as:
        // <template #copy="{ cls, codeText, copy }">
        //   <button :class="cls" @click="copy">My Copy Btn!</button>
        // </template>
        ? copySlot({ cls: copyHandleClass, style, codeText, copy })
        // default unstyled copy btn
        : this.$createElement('button', {
          staticClass: copyHandleClass,
          on: { click: copy },
          style,
        }, [this.$t('layout.CopyToClipboard')])
    },

    mountHandles(vDomNodes) {
      return this.createVueInstance({
        render(h) {
          return h('div', vDomNodes)
        },
      }).$mount().$el.childNodes
    },

    // for tests
    async _depsResolved() {
      await TuiViewer()
    },
  },
}
</script>

<style lang="sass">
@import '../scss/tui-editor'

.MarkdownViewer
  pre
    position: relative

  &__copy-handle
    position: absolute
    top: 8px
    right: 8px

  .toastui-editor-contents img
    cursor: pointer

  .toastui-editor-contents
    color: #38364D
    font-size: 13px
    font-family: Roboto, sans-serif

    .imageCaption__caption
      max-width: #{'clamp(100px, 50vw, 100%)'}
      margin-bottom: 8px
      text-align: center
      display: inline-block
      position: relative
      width: auto
      overflow: visible
      cursor: pointer

    .imageCaption__caption-img
      margin: 2px auto 0

    .imageCaption__caption-caption
      background: rgba(0, 0, 0, .5)
      font-size: 12px
      padding: 2px 8px
      color: white
      position: absolute
      bottom: 0
      left: 0
      right: 0
      width: calc(100% - 8px * 2)

    // not ported from hive yet
    //.imageError__replacement
    //  max-width: #{'clamp(100px, 50vw, 100%)'}
    //  margin-bottom: 8px
    //  text-align: center
    //  display: inline-block
    //  position: relative
    //  width: 280px
    //  height: 280px + 2px
    //
    //.imageError__replacement-img
    //  margin: 2px auto 0
    //  width: 280px
    //  height: 280px
    //
    //.imageError__replacement-caption
    //  display: block
    //  background: rgba(255, 80, 101, 0.85)
    //  font-size: 12px
    //  padding: 2px 8px
    //  color: white
    //  position: absolute
    //  bottom: 4px
    //  left: 0
    //  width: 280px - 8px * 2
    //  overflow: hidden
    //  text-overflow: ellipsis

    img
      max-width: #{'clamp(100px, 50vw, 100%)'}

      &.imageCaption__caption-img, &.imageError__caption-img
        max-width: 100%
</style>
