import { wait, animationFrame } from '@/helpers'

const CLS_BASE = 'imageCaption'

export default {
  created() {
    this.$imageCaptionMixinUnlisten = null
  },

  mounted() {
    // TODO: figure out why DOM may be not ready yet
    this.$nextTick(() => this.listenImgLoad())
  },

  updated() {
    this.$nextTick(() => this.listenImgLoad())
  },

  watch: {
    source: 'listenImgLoad',
    gallery: 'listenImgLoad',
  },

  methods: {
    async listenImgLoad() {
      if (!this.$el?.querySelectorAll) await this.$nextTick()
      if (!this.$el?.querySelectorAll) await animationFrame()
      if (!this.$el?.querySelectorAll) await wait(200) // best that we can do now?
      if (!this.$el?.querySelectorAll) return

      Array.from(this.$el.querySelectorAll(`.toastui-editor-contents img:not(.${CLS_BASE}__caption-img)`))
        .forEach(img => {
          // if (img.naturalWidth > 0 && img.naturalHeight > 0) {
          //   return this.imgOnLoad(img)
          // }
          img.addEventListener('load', this.imgOnLoad, { passive: true, once: true })
        })
    },

    imgOnLoad(event) {
      const img = event?.target || event
      if (img.classList.contains(`${CLS_BASE}__caption-img`)) return

      // caption underneath `img` should be `${img.alt}: ${img.title}`
      const originalAlt = img.alt?.trim?.()
      const originalTitle = img.title?.trim?.()
      const captionText = [originalAlt, originalTitle]
        .filter(Boolean)
        .join(': ')
      img.setAttribute('data-caption', captionText)

      // each image emits Vue-event `@click:img` on click
      const src = img.src
      const imgCaptionOnClick = e => {
        const allImgs =
          // this queries all the markdown viewers in the document,
          // not only this particular one
          Array.from(document.querySelectorAll(
            `.MarkdownViewer${this.gallery ? `[data-gallery="${this.gallery}"]` : ''} .toastui-editor-contents img`))
            // exclude hidden elements
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
            .filter(el => el.offsetParent != null)
        this.$emit('click:img', {
          img: e.target,
          src,
          caption: captionText,
          allSrcs: allImgs.map(img => img.src),
          allCaptions: allImgs.map(img => img.getAttribute('data-caption')),
        })
      }

      const addClickListener = el => {
        el.addEventListener('click', imgCaptionOnClick, false)
      }

      // doesn't replace `img` because there is no caption text
      if (!captionText) return addClickListener(img)

      // create replacement img
      const newImg = document.createElement('img')
      newImg.classList.add(`${CLS_BASE}__caption-img`)
      newImg.src = img.src
      newImg.alt = originalAlt
      newImg.title = originalTitle
      newImg.setAttribute('data-caption', captionText)

      // create caption span
      const caption = document.createElement('span')
      caption.classList.add(`${CLS_BASE}__caption-caption`)
      caption.textContent = captionText

      // create wrapper to replace `img` with wrapper + img + caption
      const replaceImgWith = document.createElement('span')
      replaceImgWith.classList.add(`${CLS_BASE}__caption`)
      replaceImgWith.append(newImg, caption)
      addClickListener(replaceImgWith)

      img.replaceWith(replaceImgWith)
    },
  },
}
